import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { UserIdleService } from 'angular-user-idle';

import { GeneralService } from '../general.service';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagesComponent implements OnInit {
  public showMenu: boolean = false;
  public showSetting: boolean = false;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  signinvalue: any;
  private isPageRefresh = true;
  Logindet: any;
  public settings: Settings;
  constructor(public userIdle: UserIdleService, public appSettings: AppSettings,
    public generalService: GeneralService, public router: Router  ) {
    if (localStorage.getItem("LoginDetails") != null) {
      this.Logindet = JSON.parse(localStorage.getItem("LoginDetails"));
    }

    this.settings = this.appSettings.settings;
    this.signinvalue = localStorage.getItem("keepsignin");
    if (sessionStorage["skin"]) {
      this.settings.theme.skin = sessionStorage["skin"];
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    if (this.isPageRefresh) {
      // Page is being refreshed, don't show the confirmation message
      return;
    }
    event.preventDefault();
    event.returnValue = '';
  }

  @HostListener('window:unload', ['$event'])
  handleUnload(event: Event) {
    this.isPageRefresh = false;
  }

  showMessage() {
    return 'Are you sure you want to leave?';
  }
  ngOnInit() {
    if (this.signinvalue != "true") {
      //Start watching for user inactivity.
      this.userIdle.startWatching();
      // Start watching when user idle is starting and reset if user action is there.
      this.userIdle.onTimerStart().subscribe(count => {
        var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel", "mousedown", "touchstart", "touchmove", "scroll", "keyup"];
        for (let event of eventList) {
          document.body.addEventListener(event, () => this.userIdle.resetTimer());
        }
      });
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        this.userIdle.stopWatching();
        var UploadFile = new FormData();
        debugger
        UploadFile.append("Id", this.Logindet.Id);
        UploadFile.append("status", '0');

        var url = "api/LauruslabsTimesheet/UpdateLoginStatus";
        debugger
        this.generalService.PostData(url, UploadFile).then(data1 => {


        })
        localStorage.removeItem("LoginDetails");
        localStorage.removeItem("keepsignin");
        this.router.navigate(['/login']);
        this.generalService.ShowAlert('INFO', 'Your Session has been expired.', 'info');
      });
    }

    if (window.innerWidth <= 768) {
      this.settings.theme.showMenu = false;
      this.settings.theme.sideChatIsHoverable = false;
    }
    this.showMenu = this.settings.theme.showMenu;
    this.menuOption = this.settings.theme.menu;
    this.menuTypeOption = this.settings.theme.menuType;
  }

  public chooseMenu(menu) {
    this.settings.theme.menu = menu;
    this.router.navigate(['/']);
  }

  public chooseMenuType(menuType) {
    this.settings.theme.menuType = menuType;
    jQuery('.menu-item-link').tooltip({
      sanitize: false,
      sanitizeFn: function (content) {
        return null;
      }
    });
    if (menuType == 'mini') {
      jQuery('.menu-item-link').tooltip('enable');
    } else {
      jQuery('.menu-item-link').tooltip('disable');
    }
  }

  public changeTheme(theme) {
    this.settings.theme.skin = theme;
    sessionStorage["skin"] = theme;
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }


  @HostListener('window:resize')
  public onWindowResize(): void {
    let showMenu = !this._showMenu();

    if (this.showMenu !== showMenu) {
      this.showMenuStateChange(showMenu);
    }
    this.showMenu = showMenu;
  }

  public showMenuStateChange(showMenu: boolean): void {
    this.settings.theme.showMenu = showMenu;
  }

  private _showMenu(): boolean {
    return window.innerWidth <= 768;
  }

}

