
  <div class="row" style="margin-left:40px">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div style="margin:0.8rem;">
        <div class="row div_card_exist" [routerLink]="['/CompanyManagement/Details',1]">
          <div class="col-lg-2 col-ms-2 col-sm-12 col-xs-12">
            <div>
              <img class="icon top-navbar" src="https://img.icons8.com/material/30/ffffff/add-contact-to-company--v1.png" />
            </div>
          </div>
          <div class="col-lg-10 col-ms-10 col-sm-12 col-xs-12">
            <div class="div_heading" [routerLink]="['/CompanyManagement/Details',1]">Company Managment</div>
            <div>
              <ul>
                <li [routerLink]="['/CompanyManagement/Details',1]">Companies</li>
                <li [routerLink]="['/CompanyManagement/Details',2]">Status</li>
                <li [routerLink]="['/CompanyManagement/Details', 3]">Type</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div style="margin:0.8rem;">
        <div class="row div_card">
          <div class="col-lg-2 col-ms-2 col-sm-12 col-xs-12">
            <div>
              <img class="icon top-navbar" src="https://img.icons8.com/material/30/ffffff/gender-neutral-user--v1.png" />
            </div>
          </div>
          <div class="col-lg-10 col-ms-10 col-sm-12 col-xs-12">
            <div class="div_heading">User Managment</div>
            <div>
              <ul>
                <li>Type</li>
                <li>Status</li>
                <li>Location</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div style="margin:0.8rem;">
        <div class="row div_card">
          <div class="col-lg-2 col-ms-2 col-sm-12 col-xs-12">
            <div>
              <img class="icon top-navbar" src="https://img.icons8.com/material-sharp/30/ffffff/crowd.png" />
            </div>
          </div>
          <div class="col-lg-10 col-ms-10 col-sm-12 col-xs-12">
            <div class="div_heading">Role Managment</div>
            <div>
              <ul>
                <li>
                  Access Request &Invitations
                </li>
                <li>Permission Levels</li>
                <li>Type</li>
                <li>Site,Form & Field Permissions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div class="row"style="margin-left:40px">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div style="margin:0.8rem;">
        <div class="row div_card_exist" [routerLink]="['/Projects/Details',1]">
          <div class="col-lg-2 col-ms-2 col-sm-12 col-xs-12">
            <div>
              <img class="icon top-navbar" src="https://img.icons8.com/material/30/ffffff/pricing-structure--v1.png" />
            </div>
          </div>
          <div class="col-lg-10 col-ms-10 col-sm-12 col-xs-12">
            <div class="div_heading">Project Managment</div>
            <div>
              <ul>
                <li [routerLink]="['/Projects/Details',1]">Projects</li>
                <li [routerLink]="['/Projects/Details',2]">Project Status</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div style="margin:0.8rem;">
        <div class="row div_card">
          <div class="col-lg-2 col-ms-2 col-sm-12 col-xs-12">
            <div>
              <img class="icon top-navbar" src="https://img.icons8.com/material/30/ffffff/goal.png" />
            </div>
          </div>
          <div class="col-lg-10 col-ms-10 col-sm-12 col-xs-12">
            <div class="div_heading">Job Managment</div>
            <div>
              <ul>
                <li>Type</li>
                <li>Status</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div style="margin:0.8rem;">
        <div class="row div_card_exist" [routerLink]="['/TaskType']">
          <div class="col-lg-2 col-ms-2 col-sm-12 col-xs-12">
            <div>
              <img class="icon top-navbar" src="https://img.icons8.com/material/30/ffffff/system-information--v1.png" />
            </div>
          </div>
          <div class="col-lg-10 col-ms-10 col-sm-12 col-xs-12">
            <div class="div_heading">Task Managment</div>
            <div>
              <ul>
                <li [routerLink]="['/TaskType']">Task Type</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>










  <div class="row"style="margin-left:40px">

    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div style="margin:0.8rem;">
        <div class="row div_card_exist" [routerLink]="['/WorkabilityType']">
          <div class="col-lg-2 col-ms-2 col-sm-12 col-xs-12">
            <div>
              <img class="icon top-navbar" src="https://img.icons8.com/material/30/ffffff/work.png" />
            </div>
          </div>
          <div class="col-lg-10 col-ms-10 col-sm-12 col-xs-12">
            <div class="div_heading">
              Workability Managment
            </div>
            <div>
              <ul>
                <li [routerLink]="['/WorkabilityType']">Type</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

