import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators'
import { GeneralService } from '../../general.service';


@Component({
  selector: 'app-employees-login',
  templateUrl: './employees-login.component.html',
  styleUrls: ['./employees-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeesLoginComponent  {
  logindata: string;
  public router: Router;
  public form: FormGroup;
  public email: AbstractControl;
  public password: AbstractControl;
  passwordshow: boolean = true;

  constructor(router: Router, fb: FormBuilder, public generalService: GeneralService) {
    localStorage.removeItem("LoginDetails");
    this.router = router;
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required, EmailValidators.normal])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });

    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
  }

  public onSubmit(values: Object): void {
    if (this.form.valid) {
      debugger
      var arr = [];
      arr.push({ Mobile: this.form.value.email, Password: this.form.value.password });
      var UploadFile = new FormData();
      UploadFile.append("Params", JSON.stringify(arr));
      var url = "api/HospitalToken/OPAdmin_Login";
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger
        if (data != null) {
          if (data != "Invalid User") {

            this.logindata = JSON.stringify(data[0]);
            localStorage.setItem("LoginDetails", this.logindata);
            this.router.navigate(['/Dashboard']);

          }
          else {
            this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
          }
        }
        else {
          this.generalService.ShowAlert("WARNING", "Please Enter Valid Credintials", "warning");
        }
      }, error => ({
        title: 'ERROR',
        text: 'Something went wrong. Please try again later.',
        type: 'error',
        confirmButtonText: 'Ok'

      })
      );

    }
  }

  /*Show passwords*/
  ShowPassword(id) {
    debugger;
    var x;
    x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      this.passwordshow = false;
    } else {
      x.type = "password";
      this.passwordshow = true;
    }



  }
  /*end code*/

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}

