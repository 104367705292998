
<div class="container">
  <div class="row" style="width:100%;margin:0px;">
    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
      <img src="../../../assets/img/app/login_image.jpg" class="login_img_mobile" style="margin-top: 4rem;" />
    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 login_bg">
      <div style="text-align:center;">MyTimeSheet</div>


      <div class="admin_headiang">Hello User.!</div>
      <div>
        <form  (ngSubmit)="onSubmit(form.value)" class="text-left mt-4">
          <div class="form-group">
            <input class="form-control validation-field" placeholder="Email" type="text">
            </div>
          <div class="form-group">
            <input class="form-control validation-field" placeholder="Password" type="password" id="myInput">
            
                     </div>
          <div class="form-group d-flex justify-content-between">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="login-checkbox">
              <label class="custom-control-label" for="login-checkbox">Keep me signed in.</label>
            </div>
            <a class="transition pull-right" style="cursor:pointer;">Forgot password?</a>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-lg-7 col-md-7">

              </div>
              <div class="col-lg-5 col-md-5">
                <button class="login__button" type="submit">Sign in</button>
              </div>
            </div>


          </div>
        </form>






      </div>
    </div>
  </div>
</div>



