import { Component, ViewEncapsulation, OnDestroy, OnInit,HostListener} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { GeneralService } from './general.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent  {
  Logindet: any;
  public settings: Settings;
  //private isPageRefresh = true;
  constructor(public appSettings: AppSettings, public translate: TranslateService, private router: Router, public generalService: GeneralService){
        this.settings = this.appSettings.settings; 
        translate.addLangs(['en','de','fr','ru','tr']);
        translate.setDefaultLang('en'); 
    translate.use('en');
    if (localStorage.getItem("LoginDetails") != null) {
      this.Logindet = JSON.parse(localStorage.getItem("LoginDetails"));
    }

  }
  ngOnInit() {
    //window.addEventListener("beforeunload", function (e) {
    //  var confirmationMessage = "\o/";
    //  (e || window.event).returnValue = confirmationMessage; //Gecko + IE//Webkit, Safari, Chrome
    //  localStorage.removeItem("LoginDetails");
    //  return confirmationMessage;
    //});
  //  window.onbeforeunload = function (event) {
  //    var message = 'Important: Please click on \'Save\' button to leave this page.';
  //    if (typeof event == 'undefined') {
  //      event = window.event;
  //    }
  //    if (event) {
  //      localStorage.removeItem("LoginDetails");
  //      event.returnValue = message;
  //    }
  //    return message;
  //  };
  }
  //@HostListener('window:beforeunload', ['$event'])
  //clearLocalStorage(event: Event) {
  //  localStorage.removeItem("LoginDetails");
  //}
 
  //@HostListener('window:beforeunload', ['$event'])
  //handleBeforeUnload(event: BeforeUnloadEvent) {
  //  if (this.isPageRefresh) {
  //    // Page is being refreshed, don't show the confirmation message
  //    return;
  //  }
  //  event.preventDefault();
  //  event.returnValue = '';
  //}

  //@HostListener('window:unload', ['$event'])
  //handleUnload(event: Event) {
  //  this.isPageRefresh = false;
  //}

  //showMessage() {
  //  return 'Are you sure you want to leave?';
  //}

  //@HostListener('window:beforeunload', ['$event'])
  //beforeUnloadHandler(event: BeforeUnloadEvent) {
  //  var UploadFile = new FormData();
  //  UploadFile.append("Id", this.Logindet.Id);
  //  UploadFile.append("status", '0');
  //  var url = "api/LauruslabsTimesheet/UpdateLoginStatus";
  //  this.generalService.PostData(url, UploadFile).then(data1 => {
  //  })
  //  localStorage.removeItem("LoginDetails");
  //  localStorage.removeItem("keepsignin");
  //}
}

