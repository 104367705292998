import { Component, OnInit, ViewEncapsulation, HostListener, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { Router } from '@angular/router';
import { GeneralService } from '../../../general.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
  animations: [
    trigger('showInfo', [
      state('1', style({ transform: 'rotate(180deg)' })),
      state('0', style({ transform: 'rotate(0deg)' })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  Logindet: any;
  public showHorizontalMenu: boolean = true;
  public showInfoContent: boolean = false;
  public settings: Settings;
  public menuItems: Array<any>;

  
  constructor(public appSettings: AppSettings, public menuService: MenuService, public router: Router, public generalService: GeneralService) {
    if (localStorage.getItem("LoginDetails") != null) {
      this.Logindet = JSON.parse(localStorage.getItem("LoginDetails"));
    }
    this.settings = this.appSettings.settings;
    this.menuItems = this.menuService.getHorizontalMenuItems();
     
  }
 
 
  ngOnInit() {
    if (window.innerWidth <= 768)
      this.showHorizontalMenu = false;
  }
 

  public closeSubMenus() {
    let menu = document.querySelector("#menu0");
    if (menu) {
      for (let i = 0; i < menu.children.length; i++) {
        let child = menu.children[i].children[1];
        if (child) {
          if (child.classList.contains('show')) {
            child.classList.remove('show');
            menu.children[i].children[0].classList.add('collapsed');
          }
        }
      }
    }
  }


  
  logout() {
    localStorage.removeItem("LoginDetails");
    this.router.navigate(['/login']);
    var UploadFile = new FormData();
    debugger
    UploadFile.append("Id", this.Logindet.Id);
    UploadFile.append("status", '0');

    var url = "api/LauruslabsTimesheet/UpdateLoginStatus";
    debugger
    this.generalService.PostData(url, UploadFile).then(data1 => {


    })
  }



}


