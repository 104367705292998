import { Injectable, OnDestroy, OnInit} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';

import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  url: any;
  urlname: any;
  Logindet: any;

  constructor(public http: HttpClient) {

  }

 
  ShowAlert(title, text, type) {
    Swal.fire({
      title: title,
      html: text,
      //icon: type,
      confirmButtonText: 'Ok'
    })
  }
  ShowAlert1(title, text, type) {
    Swal.fire({
      title: title,
      html: text,
      //icon: type,
      confirmButtonText: 'Print',
      //confirmButtonText1: 'Cancel'
    })
  }
  //Global Method to retrieve data 
  GetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
    
        this.urlname = data.Webservice;
        this.url = this.urlname + url
        this.http.get(this.url).subscribe(data => {

          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
 async PostData(url, PostParam) {
    
    return new Promise((resolve, reject) => {
      
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        
        this.urlname = data.Webservice;
        url = data.Webservice + url
        this.http.post(url, PostParam).subscribe(data => {
          debugger
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData2(url, PostParam, PostParam2) {
    debugger;
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.post(url, PostParam, PostParam2).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
}
