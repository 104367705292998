import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  /*end code*/

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }
}
